import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import './ActivityDialog.css';
import { SolCheckbox } from '@solstice/sol-react';
import AeroModal from '../AeroModal/AeroModal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autosuggest from 'react-autosuggest';
import { isEmpty, debounce } from 'lodash';
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import * as UsersActions from "../../store/actions/Users.actions";
import * as ActivityActions from "../../store/actions/Activity.actions";
import * as DealioActions from "../../store/actions/Dealio.actions";
import moment from 'moment';
import { getUser } from '../../utils/tokenManager';
import { autoSuggestScrollToElement } from "../../utils/helpers";
import { Oval } from "react-loader-spinner";
import { setGA4Data } from "../../utils/googleAnalytics";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import { Input } from 'rsuite';

const ActivityDialogDesktop = (props) => {
  const email = getUser()
  const userName = email.substring(0, email.indexOf("@"));
  const roundDateToNearest15Min = (dt, offset = 0) => {
    dt.setMilliseconds(Math.round(dt.getMilliseconds() / 1000) * 1000);
    dt.setSeconds(Math.round(dt.getSeconds() / 60) * 60);
    dt.setMinutes(Math.round(dt.getMinutes() / 15) * 15);
    dt.setHours(dt.getHours() + offset);
    return dt;
  }
  const userProfile = useSelector((state) => state.users.userProfile);
  const users = useSelector((state) => state.users.users);
  const dealio = useSelector((state) => state.dealio.dealio);
  const activity = useSelector((state) => state.activity.activity);

  const initActivity = {
    user: userProfile.isAdministrator,
    id: 0,
    dealioId: 0,
    subject: '',
    activityType: 'appointment',
    dueDate: moment(roundDateToNearest15Min(new Date())).format(),
    doNotSetNextCallDate: false,
    nextCallDate: moment(roundDateToNearest15Min(new Date())).format(),
    regardingId: props.regarding.id,
    regardingName: props.regarding.name,
    regardingTypeId: props.regarding.type,
    isPrivate: false,
    isSubstantialActivity: false,
    ownerId: userProfile.Id,
    jllContacts: {},
    description: '',
    addToList: [],
    isCompleted: false,
  }

  const {
    isOpen,
    regarding,
    SetExitModal,
    SetActivityEditId,
    closeActivityModalBtnClicked,
    activityEditId,
    activityType,
    activityActions,
    dealioActions,
    editMode,
    usersActions,
  } = props;

  const [activityData, setActivityData] = useState(initActivity);
  const [ownerChanged, setOwnerChanged] = useState(false);
  const [ownerSuggestions, setOwnerSuggestions] = useState([]);
  const [ownerValue, setOwnerValue] = useState(userName);
  const [dealioIdSearch, setDealioIdSearch] = useState('');
  const [errorArr, setErrorArr] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [ownerActive, setOwnerActive] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      activityData: activityData
    },
  });
  const onSubmit = () => {
    editMode === 'add' ? handleAddEditActivity('add') : handleAddEditActivity('edit');
  }

  useEffect(() => {
    if (activityEditId > 0) {
      activityActions.getActivityById({ id: activityEditId, type: activityType })
    } else {
      setActivityData(initActivity);
      reset({ activityData: initActivity });
    }
  }, [activityEditId]);

  useEffect(() => {
    if (!isEmpty(activity) && activityEditId > 0) {
      setTeamMembers(
        activity.jllContacts.map((item) => ({ label: item.name }))
      );
      setActivityData(activity);
      activity.ownerId = activity.owner.id;
      activity.regardingId = activity.regarding.id;
      activity.regardingTypeId = activity.regarding.type;
      activity.addToList = !activity.isCompleted;
      activity.doNotSetNextCallDate = false;
      const owner = activity.owner.displayName ? activity.owner.displayName : '';
      setOwnerValue(owner);
      setDealioIdSearch(activity.dealio?.displayName);
      
      reset({ activityData: activity });
    }
    else {
      setActivityData(initActivity);
    }
  }, [activity]);

  useEffect(() => {
    if (!isEmpty(users)) {
      if (users.length === 1 && ownerSuggestions.length === 0 && ownerValue === users[0].fullName) {
        //Do Nothing
      } else {
        const act = { ...activityData };
        setOwnerSuggestions(users);
        if (ownerChanged === false && users.length === 1 && users[0] && activityData.ownerId === 0) {
          act['owner'] = { id: users[0].id, displayName: users[0].fullName };
          act['ownerId'] = users[0].id;
          setActivityData(act);
          setOwnerValue(users[0].fullName);
          reset({ activityData: act });
        }
      }
    }
  }, [users, ownerValue])

  const handleClose = () => {
    SetExitModal(false);
    SetActivityEditId(0);
    activityActions.clearActivity();
  }

  const teamMemberSearch = useMemo(() => debounce(inputValue => {
    usersActions.getUsers({ query: inputValue, isAdmin: userProfile?.isAdministrator });
  }, 600), []);

  const teamMemberList = async inputValue => {
    if (inputValue.trim().length >= 3) {
      teamMemberSearch(inputValue);
      const newArray = users.map(item => ({
        value: item.id,
        label: item.fullName + `${item.isActive === false ? ' (Inactive)' : ''}`,
        isActive: item.isActive,
      }));
      return newArray;
    }
    return [{}];
  };

  const handleChange = selectedOption => {
    const selectedUserIds = new Set(
      (selectedOption && selectedOption.map(obj => obj.value)) || null
    );
    const updatedInitialObjects =
      (initActivity.initialContactsData &&
        initActivity.initialContactsData.map(obj => {
          if (selectedUserIds.has(obj.UserId)) {
            return { ...obj, isActive: true };
          }
          return { ...obj, isActive: false };
        })) ||
      [];

    // Add new Members
    if (selectedOption) {
      selectedOption.forEach(selectedObj => {
        if (
          (initActivity.initialContactsData &&
            !initActivity.initialContactsData.some(
              obj => obj.UserId === selectedObj.value
            )) ||
          initActivity.initialContactsData === null
        ) {
          updatedInitialObjects.push({
            Id: 0, // Make Id as 0 for new contact
            UserId: selectedObj.value,
            isPrimary: false,
            isActive: true,
          });
        }
      });
    }

    setTeamMembers(selectedOption);
    // change('activityTeamMembers', updatedInitialObjects);
  };

  const getOwnerSuggestions = value => {
    if (!isEmpty(value) && value.length >= 3) {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      return inputLength === 0 && !isEmpty(ownerSuggestions) ? [] : ownerSuggestions.filter(o =>
        o.fullName.toLowerCase().slice(0, inputLength) === inputValue
      );
    } else {
      return [];
    }
  };

  const setActivityValue = (field, val) => {
    const act = { ...activityData };
    act[field] = val;
    console.log(act);
    setActivityData(act);
  }

  const onTextAreaValueChange = (e, field) => {
    const act = { ...activityData };
    act[field] = e.target.value;
    setActivityData(act);
  }

  const onCheckValueChange = (field) => {
    const act = { ...activityData };
    act[field] = !act[field];
    setActivityData(act);
  }

  const onDatePickerChange = (val, dtField) => {
    const act = { ...activityData };
    act['endDate'] = moment(val).format();
    act[dtField] = moment(val).format();
    setActivityData(act);
  }

  const getDealioSuggestionValue = suggestion => {
    if (suggestion.displayName || suggestion.dealioId) {
      return (
        suggestion.displayName ||
        `${suggestion.dealioId} - ${suggestion.companyName} - ${suggestion.dealName}`
      );
    }

    return '';
  };

  const getOwnerSuggestionValue = suggestion => {
    if (suggestion.displayName || suggestion.lastName) {
      // suggestion => user
      if (suggestion.isActive === false) {
        return (
          `${suggestion.displayName} (Inactive)` ||
          `${suggestion.firstName} ${suggestion.lastName} (Inactive)`
        );
      }
      return (
        suggestion.displayName || `${suggestion.firstName} ${suggestion.lastName}`
      );
    }

    return '';
  };

  const renderDealioSuggestion = suggestion => (
    <div className="dvSuggest">
      {`${suggestion.dealioId} - ${suggestion.dealioClientName} - ${suggestion.dealName}`}
    </div>
  );

  const renderOwnerSuggestion = suggestion => (
    <div className="dvSuggest">
      {suggestion.fullName}
    </div>
  );

  const onOwnerChange = (event, { newValue }) => {
    if (newValue.length <= 3) {
      const act = { ...activityData };
      act['owner'] = {};
      act['ownerId'] = 0;
      setOwnerChanged(true);
      setActivityData(act);
      reset({ activityData: act });
    }
    setOwnerValue(newValue);
  };

  const onDealioChange = (event, { newValue }) => {
    if (newValue?.length <= 3) {
      const act = { ...activityData };
      act['dealioId'] = 0;
      setOwnerChanged(true);
      setActivityData(act);
      reset({ activityData: act });
    }
    setDealioIdSearch(newValue);
  };

  const onDealioSuggestionsFetchRequested = useMemo(() => debounce(value => {
    value.value.length > 2 && dealioActions.getDealioResults({ SearchString: value.value });
  }, 600), []);

  const onOwnerSuggestionsFetchRequested = useMemo(() => debounce(value => {
    value.value.length > 2 && usersActions.getUsers({ query: value.value });
  }, 600), []);

  const onDealioSuggestionsClearRequested = () => {
    dealioActions.clearDealioResults();
  };

  const onOwnerSuggestionsClearRequested = () => {
    const suggestions = getOwnerSuggestions([]);
    setOwnerSuggestions(suggestions);
  };

  const onDealioSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
    event.preventDefault();
    const act = { ...activityData };
    act['dealioId'] = suggestion.dealioId;

    clearErrorForField('dealioId');
    setActivityData(act);
    onDealioSuggestionsClearRequested();
    reset({ activityData: act });
  }

  const onOwnerSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
    event.preventDefault();
    const selection = suggestion;
    const act = { ...activityData };
    act['owner'] = { id: selection.id, displayName: selection.fullName };
    act['ownerId'] = selection.id;

    clearErrorForField('owner');
    setActivityData(act);
    setOwnerChanged(true);
    onOwnerSuggestionsClearRequested();
    reset({ activityData: act });
  }

  const renderDealioInputComponent = dealioInputProps => (
    <div>
      <input
        {...dealioInputProps}
      />
    </div>
  );

  const renderOwnerInputComponent = ownerInputProps => (
    <div>
      <input
        {...ownerInputProps}
      />
      <sol-icon
        size="20"
        style={{ color: '#637177', position: 'absolute', right: '5px', marginTop: '13px' }}
        icon="search"
      />
      <sol-icon
        size="24"
        style={{ display: !ownerValue && 'none', float: 'right', margin: '-34px 5px 0px 0px' }}
        icon="close"
        onClick={() => { setOwnerValue(''); }}
      />
    </div>
  );

  const clearErrorForField = (name) => {
    const errArr = [...errorArr];
    const index = errArr.indexOf(name);
    if (index > -1) {
      errArr.splice(index, 1);
    }
    setErrorArr(errArr);
  }

  const handleAddEditActivity = async (activityText) => {
    const { regarding } = props;
    if (activityText === 'add') {
      setGA4Data('ActivityCreated', { companyID: regarding.id });
    } else {
      setGA4Data('ActivityModified', { companyID: regarding.id });
    }
    activityActions.addEditActivity({
      activityData, cb: (flag) => {
        setShowLoader(flag);
        if (!flag) {
          closeActivityModalBtnClicked();
        }
      }
    });
  }

  const customStyles = {
    container: provided => ({
      ...provided,
      width: '100%',
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.userIsActive === false || data.isActive === false ? 'rgb(230, 230, 230, .4)' : 'rgb(230, 230, 230)',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.userIsActive === false || data.isActive === false ? 'rgb(51, 51, 51, .4)' : 'rgb(51, 51, 51)',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: 0,
    }),
  };

  const validateForm = () => {
    const act = { ...activityData }
    let blnValid = true;
    const arrErr = [...errorArr]

    if (!act.subject || act.subject === '') {
      arrErr.push('subject');
      blnValid = false;
    }
    if (!act.regarding || Object.keys(act.regarding).length === 0) {
      arrErr.push('regarding');
      blnValid = false;
    }
    if (!act.owner || !(act.owner.id > 0)) {
      arrErr.push('owner');
      blnValid = false;
    }

    if (act.activityType === 'phoneCall') {
      if (!act.dueDate || act.dueDate === '') {
        blnValid = false;
      }
    } else if (act.activityType === 'appointment') {
      if (!act.startDate || act.startDate === '') {
        blnValid = false;
      }
      const dtStart = new Date(act.startDate);
    }

    if (blnValid === true) {
      setErrorArr([]);
    } else {
      setErrorArr(arrErr);
    }

    return blnValid
  }

  const shouldRenderSuggestions = () => {
    return true;
  }

  return (
    <div className='ActivityDialogDesktop' id='ActivityDialog'>
      {console.log(activityData)}
      <form onSubmit={handleSubmit(onSubmit)}>
        {showLoader && <div style={{
          display: 'flex',
          justifyContent: 'center'
        }}> <Oval
            visible={true}
            height="40"
            width="40"
            color="#000000"
            secondaryColor={"gray"}
            ariaLabel="oval-loading"
            wrapperStyle={{ zIndex: 1000000 }}
            wrapperClass=""
          />
        </div>}
        {!showLoader &&
          <AeroModal
            dialogClassName="activityModal"
            title={editMode === "edit" ? "Edit Activity" : "Create Activity"}
            handleClose={() => { handleClose() }}
            isOpen={isOpen}
            primaryButtonText='Save'
            handlePrimaryButtonClick={handleSubmit(onSubmit)}
            secondaryButtonText='Cancel'
            handleSecondaryButtonClick={() => { handleClose() }}
            tertiaryButtonText='Delete'
            handleTertiaryButtonClick={() => { setIsDeleteModalOpen(true) }}
          >
            <div>
              {userProfile?.isAdministrator &&
                <div>
                  <div for="dealioId" id="dealioId" className='activityModalLabel'>Deal ID</div>
                  <Controller
                    name='activityData.dealioId'
                    control={control}
                    render={() => (
                      <Autosuggest
                        theme={{
                          input: errors?.activityData?.dealioId ? "auto-suggest-input-error" : "auto-suggest-input",
                          suggestionsContainer: 'auto-suggest-container',
                          suggestion: 'auto-suggest-suggestion',
                          suggestionHighlighted: 'suggestion-highlighted'
                        }}
                        renderInputComponent={renderDealioInputComponent}
                        suggestions={dealio || []}
                        shouldRenderSuggestions={shouldRenderSuggestions}
                        onSuggestionsFetchRequested={onDealioSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onDealioSuggestionsClearRequested}
                        getSuggestionValue={getDealioSuggestionValue}
                        renderSuggestion={renderDealioSuggestion}
                        onSuggestionSelected={(event, { suggestion }) => {
                          // Setting contacts
                          if (suggestion.jllContacts) {
                            const dealioContacts = [];
                            const dealioUIContacts = [];
                            suggestion.jllContacts.forEach(contact => {
                              if (contact.userId > 0) {
                                dealioUIContacts.push({
                                  value: contact.userId,
                                  label: contact.isActive === false ? contact.name + ' (Inactive)' : contact.name,
                                  userIsActive: contact.userIsActive,
                                });
                                dealioContacts.push({
                                  Id: 0, // Make Id as 0 for new contact
                                  UserId: contact.userId,
                                  isPrimary: false,
                                  isActive: true,
                                });
                              }
                            });
                            setActivityValue('jllContacts', dealioContacts);
                            setTeamMembers(dealioUIContacts);
                          }
                          // Setting owner
                          if (suggestion.owner) {
                            const ownerObj = {
                              "id": suggestion.owner.id,
                              "displayName": suggestion.owner.displayName + `${suggestion.owner.userIsActive === false ? ' (Inactive)' : ''}`,
                              "email": suggestion.owner.email,
                              "userIsActive": suggestion.owner.userIsActive,
                            }
                            setActivityValue('owner', ownerObj);
                            if (ownerObj.userIsActive === true) {
                              setOwnerActive(true)
                            } else {
                              setOwnerActive(false);
                            }
                          }

                          // Setting Subject
                          const { dealioStreetAddress, dealioCity, dealioState } = suggestion;
                          const derivedSubject = `Signed Lease @ ${dealioStreetAddress}, ${dealioCity}, ${dealioState}`;
                          setActivityValue('subject', derivedSubject);
                          setActivityValue('dealio', suggestion);
                        }}
                        inputProps={{
                          className: 'ActivityModalDekstopInput',
                          value: dealioIdSearch || '',
                          onChange: onDealioChange,
                          onClick: () => {
                            autoSuggestScrollToElement('dealioId');
                          },
                        }}
                        blurOnSelect
                        class="selectInput"
                        className="selectInput"
                      />
                    )}
                  />
                </div>}
              <div className="fieldSpacer" />

              <div for="dealioId" id="dealioId" className='activityModalLabel'>Regarding</div>
              <Input
                style={{ color: '#56656B' }}
                className='ActivityModalDekstopInput'
                value={activityData && activityData.regarding && activityData.regarding.displayName ? activityData.regarding.displayName : null}
                readonly
              />
              <div className="fieldSpacer" />

              <DatePicker
                showYearDropdown
                dateFormat="MM/dd/yyyy"
                selected={activityData && activityData.dueDate ? new Date(activityData.dueDate) : ''}
                onChange={(date) => onDatePickerChange(date, 'dueDate')}
                customInput={
                  <span>
                    <div for="dealioId" id="dealioId" className='activityModalLabel'>Date of activity</div>
                    <Input
                      className='ActivityModalDekstopInput'
                      value={activityData && activityData.dueDate ? moment(activityData.dueDate).format('MMM DD, YYYY') : ''}
                      onMouseDown={e => {
                        e.preventDefault();
                      }}
                    />
                    <sol-icon
                      size="20"
                      style={{ color: '#637177', position: 'absolute', right: '5px', top: '45px' }}
                      icon="calendar_today"
                    />
                  </span>
                }
              />
              <div className="fieldSpacer" />
              <Controller
                name='activityData.owner.displayName'
                control={control}
                rules={{ required: true }}
                render={(field) => (
                  <div>
                    <div for="owner" id="owner" className='activityModalLabel'>Lead broker<span className="redAsterisk">*</span></div>
                    <Autosuggest
                      theme={{
                        input: errors?.activityData?.owner ? "auto-suggest-input-error" : ownerValue ? "auto-suggest-input-padding" : "auto-suggest-input",
                        suggestionsContainer: 'auto-suggest-container',
                        suggestion: 'auto-suggest-suggestion',
                        suggestionHighlighted: 'suggestion-highlighted'
                    }}
                      shouldRenderSuggestions={shouldRenderSuggestions}
                      renderInputComponent={renderOwnerInputComponent}
                      style={{ border: '1px solid green !important' }}
                      suggestions={ownerSuggestions}
                      onSuggestionsFetchRequested={onOwnerSuggestionsFetchRequested}
                      onSuggestionsClearRequested={onOwnerSuggestionsClearRequested}
                      getSuggestionValue={getOwnerSuggestionValue}
                      renderSuggestion={renderOwnerSuggestion}
                      onSuggestionSelected={onOwnerSuggestionSelected}
                      inputProps={{
                        className: 'ActivityModalDekstopInput',
                        placeholder: 'Select owner',
                        value: ownerValue,
                        onChange: onOwnerChange,
                        onClick: () => {
                          autoSuggestScrollToElement('owner');
                        }
                      }}
                      blurOnSelect
                      class="selectInput"
                      className="selectInput"
                    />
                  </div>
                )}
              />
              <div className="fieldSpacer" />

              <Controller
                name='activityData.owner.displayName'
                control={control}
                rules={{ required: true }}
                render={() => (
                  <div>
                    <div for="owner" id="owner" className='activityModalLabel'>Team</div>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      loadOptions={teamMemberList}
                      noOptionsMessage={() => null}
                      value={teamMembers}
                      onChange={handleChange}
                      placeholder=""
                      styles={customStyles}
                    />
                  </div>
                )}
              />
              <div className="fieldSpacer" />

              <Controller
                name='activityData.subject'
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <div>
                    <div for="subject" className='activityModalLabel'>Subject</div>
                    <Input
                      className='ActivityModalDekstopInput'
                      value={activityData?.subject || ''}
                      state={errors?.activityData?.subject ? "error" : "neutral"}
                      onChange={(value) => { onChange(value); setActivityValue('subject', value); }}
                      placeholder="Meeting with Joshua Robinstein" class="light"
                    />
                  </div>
                )}
              />
              <div className="fieldSpacer" />

              <div for="description" className='activityModalLabel'>Description<span className="redAsterisk">*</span></div>
              <Controller
                name='activityData.description'
                control={control}
                rules={{ maxLength: 2000 }}
                render={({ field: { onChange } }) => (
                  <div>
                    <textarea id="description" name="description"
                      value={activityData?.description || ''}
                      onChange={(e) => { onChange(e.target.value); onTextAreaValueChange(e, 'description'); }}
                      rows="4" cols="46" className="descTextArea"
                      style={{ border: errors?.activityData?.description && '1px solid #b0133a' }}
                    />
                    <div className='descriptionTooltip'>
                      <sol-icon
                        size="16"
                        style={{ color: '#637177' }}
                        icon="info"
                      />
                      2000 character
                    </div>
                  </div>
                )}
              />
              <div className="fieldSpacer" />

              <div className="dvNextCallDate">
                <DatePicker
                  showYearDropdown
                  dateFormat="MM/dd/yyyy"
                  disabled={activityData && activityData.doNotSetNextCallDate === false ? false : true}
                  selected={activityData && activityData.nextCallDate ? new Date(activityData.nextCallDate) : ''}
                  onChange={(date) => onDatePickerChange(date, 'nextCallDate')}
                  customInput={
                    <span>
                      <div for="description" className='activityModalLabel'>Next meeting date</div>
                      <Input
                        className='ActivityModalDekstopInput'
                        value={activityData && activityData.nextCallDate ? moment(activityData.nextCallDate).format('MMM DD, YYYY') : ''}
                        onMouseDown={e => {
                          e.preventDefault();
                        }}
                      />
                      <sol-icon
                        size="20"
                        style={{ color: '#637177', position: 'absolute', right: '5px', top: '45px' }}
                        icon="calendar_today"
                      />
                      
                      <div className="fieldSpacer" />
                      <div className="fieldSpacer" />
                    </span>
                  }
                />
              </div>

              <div className='ActivityCheckboxes'>
                <div>
                  <SolCheckbox
                    checked={activityData && activityData.isPrivate ? activityData.isPrivate : false}
                    onSol-checked={e => onCheckValueChange('isPrivate')}
                  />
                  <span className='checkboxLabelDesktop'>
                    Confidential
                  </span>
                </div>

                <div>
                  <SolCheckbox
                    checked={activityData.addToList === true}
                    onSol-checked={e => {
                      const act = { ...activityData };
                      if (e.detail === false) {
                        act['addToList'] = [];
                        act['isCompleted'] = true;
                      } else {
                        act['addToList'] = [{ id: 1, description: "Add to To-Do List" }];
                        act['isCompleted'] = false;
                      }
                      setActivityData(act);
                    }}
                  /><span className='checkboxLabel'>Add To-do-list</span>
                </div>

                <div>
                  <SolCheckbox
                    checked={activityData && activityData.isSubstantialActivity === true ? true : false}
                    onSol-checked={e => onCheckValueChange('isSubstantialActivity')}
                  />
                  <span className='checkboxLabel'>Substantial</span>
                </div>
              </div>
              {Object.keys(errors).length ? (<span className='errorText'>* Fix Errors Above</span>) : ''}
            </div>
          </AeroModal>}
        <AeroModal
          dialogClassName="deleteModal"
          classPrefix='deleteModal'
          backdropClassName="deleteModalBackdrop"
          handleClose={() => { setIsDeleteModalOpen() }}
          isOpen={isDeleteModalOpen}
          primaryButtonText='Yes'
          primaryButtonClassName="deleteModalPrimaryBtn"
          handlePrimaryButtonClick={() => { activityActions.deleteActivity({ activityId: activityEditId }) }}
          secondaryButtonText='No'
          secondaryButtonClassName="deleteModalSecondaryBtn"
          handleSecondaryButtonClick={() => { setIsDeleteModalOpen() }}
          hideClose
        >
          <div className='deleteModalIcon'>
            <sol-icon
              size="28"
              style={{ color: '#B0133A' }}
              icon="info"
            />
          </div>
          Are you sure you want to delete the activity?
        </AeroModal>
      </form>
    </div>
  )
}

ActivityDialogDesktop.propTypes = {
  regarding: PropTypes.object,
  SetExitModal: PropTypes.func,
  SetActivityEditId: PropTypes.func,
  closeActivityModalBtnClicked: PropTypes.func,
  users: PropTypes.array,
  activityEditId: PropTypes.number,
  activityType: PropTypes.string,
  activityActions: PropTypes.object,
  activity: PropTypes.object,
  editMode: PropTypes.string,
};

ActivityDialogDesktop.defaultProps = {
  regarding: {},
  SetExitModal: () => { },
  SetActivityEditId: () => { },
  closeActivityModalBtnClicked: () => { },
  users: [],
  activityEditId: 0,
  activityType: '',
  activityActions: {},
  activity: {},
  editMode: '',
};

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(UsersActions, dispatch),
    activityActions: bindActionCreators(ActivityActions, dispatch),
    dealioActions: bindActionCreators(DealioActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ActivityDialogDesktop);
