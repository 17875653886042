import {BASE_URL, AuthHeaders} from "./config";
import queryString from 'query-string';
import axios from "axios";

export const fetchUsers = async (data) => {
    const response = await axios.get(
        `${BASE_URL}/users/?${queryString.stringify(data)}`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    return response.data;
};

export const fetchUserProfile = async () => {
    const response = await axios.get(
        `${BASE_URL}/users/me`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    return response.data;
};