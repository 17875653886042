export const GET_DEALIO_RESULTS = "GET_DEALIO_RESULTS";
export const GET_DEALIO_RESULTS_SUCCESS = "GET_DEALIO_RESULTS_SUCCESS";
export const CLEAR_DEALIO_RESULTS = "CLEAR_DEALIO_RESULTS";

export function getDealioResults(data) {
    return {
        payload: data,
        type: GET_DEALIO_RESULTS,
    };
}

export function getDealioResultsSuccess(data) {
    return {
        payload: data,
        type: GET_DEALIO_RESULTS_SUCCESS,
    };
}

export function clearDealioResults() {
  return {
      type: CLEAR_DEALIO_RESULTS,
  };
}