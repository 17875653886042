import api from "../../api/index";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    GET_DEALIO_RESULTS,
    getDealioResultsSuccess,
} from "../actions/Dealio.actions";

export function* getDealioResults(action) {
    try {
        const response = yield call(
            api.dealioApi.getDealioResults,
            action.payload
        );
        yield put(getDealioResultsSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchGetDealioResults() {
    yield takeEvery(GET_DEALIO_RESULTS, getDealioResults);
}

export default function* dealioSagas() {
    yield all([
        watchGetDealioResults(),
    ]);
}