import React from 'react';
import 'rsuite/dist/rsuite.min.css';
import { Button, Modal } from "rsuite";
import { SolIcon } from "@solstice/sol-react";


const AeroModal = (props) => {

  const {
    dialogClassName,
    classPrefix,
    backdropClassName,
    primaryButtonClassName,
    secondaryButtonClassName,
    tertiaryButtonClassName,
    title,
    handleClose,
    primaryButtonText,
    secondaryButtonText,
    tertiaryButtonText,
    handlePrimaryButtonClick,
    handleSecondaryButtonClick,
    handleTertiaryButtonClick,
    isOpen,
    size,
    hideClose,
  } = props;

  return (
    <Modal
      overflow={false}
      size={size || 'sm'}
      backdrop={'static'}
      open={isOpen}
      onHide={() => {
        handleClose();
      }}
      dialogClassName={dialogClassName}
      backdropClassName={backdropClassName}
    >
      <Modal.Header closeButton={false} style={{ display: 'flex', justifyContent: 'space-between', }}>
        <Modal.Title> {title}</Modal.Title>
        {!hideClose && <SolIcon onClick={() => handleClose()} icon={'close'} />}
      </Modal.Header>
      <Modal.Body classPrefix={classPrefix}>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        {tertiaryButtonText && 
        <Button className={tertiaryButtonClassName || 'secondaryButton'} onClick={() => handleTertiaryButtonClick()}>
          {tertiaryButtonText}
        </Button>}
        {secondaryButtonText && 
        <Button className={secondaryButtonClassName || 'secondaryButton'} onClick={() => handleSecondaryButtonClick()}>
          {secondaryButtonText}
        </Button>}
        {primaryButtonText && 
        <Button className={primaryButtonClassName || 'primaryButton'} onClick={() => handlePrimaryButtonClick()}>
          {primaryButtonText}
        </Button>}

      </Modal.Footer>
    </Modal>
  )
};

export default AeroModal;