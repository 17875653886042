import initialState from '../initialState.json';
import { GET_DEALIO_RESULTS, GET_DEALIO_RESULTS_SUCCESS, CLEAR_DEALIO_RESULTS } from "../actions/Dealio.actions";


export default function (
  state = initialState,
  action
) {
  switch (action.type) {

    case CLEAR_DEALIO_RESULTS:
    case GET_DEALIO_RESULTS: {
      return {
        ...state,
        dealio: [],
      }
    }

    case GET_DEALIO_RESULTS_SUCCESS: {
      return {
        ...state,
        dealio: [
          ...action.payload
        ],
      }
    }

    default: {
      return {
        ...state
      }
    }
  }
}