import React, { useState }from 'react';
import './ToDoListGrid.css';
import { Drawer, Tooltip } from "rsuite";
import { SolIcon } from "@solstice/sol-react";
import AeroGrid from "../AeroGrid/AeroGrid";
import * as ActivityActions from "../../../store/actions/Activity.actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActivityDialogDesktop from '../../../components/ActivityDialog/ActivityDialogDesktop';

const ToDoListGrid = (props) => {

  const { isOpen, onClose, data, activityActions } = props;
  const [activePage, setActivePage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityEditId, SetActivityEditId] = useState(0);
  
  const handlePagination = (page, column, direction) => {
    setActivePage(page);
    activityActions.sortMyActivities({
      direction: direction || '-',
      limit: 10,
      offset: (page - 1) * 10,
      sort: column || 'dueDate'
    });
  }

  const handleSort = (column, direction) => {
    setActivePage(1);
    activityActions.sortMyActivities({
      direction,
      limit: 10,
      offset: 0,
      sort: column
    });
  }

  let todoGridColumns = [{
    id: 1,
    label: 'Company',
    value: 'regarding',
    subValue: 'displayName',
    isSortable: true,
  },
  {
    id: 2,
    label: 'Team',
    value: 'jllContacts',
  },
  {
    id: 3,
    label: 'Lead market',
    value: 'city',
    isSortable: true,
  },
  {
    id: 4,
    label: 'Date of activity',
    value: 'dueDate',
    isSortable: true,
    renderToolTip: () => <Tooltip content={`Company ${activityActions.company.name}`} placement="top"></Tooltip>

  },
  {
    id: 5,
    label: '',
    value: 'edit',
    onClick: (id) => {SetActivityEditId(id); setIsModalOpen(true);}
  }];

  return (
    <div className='aeroGridContainer' >
      <Drawer className='drawerPanel' size={'lg'} open={isOpen}>
        <div className='drawerContainer'>
          <div className='drawerHeaderContainer'>
            <div className='drawerHeader'>To do list</div>
            <SolIcon icon='close' className='drawerCloseIcon' onClick={() => onClose()} />
          </div>
          <AeroGrid
            columns={todoGridColumns}
            rowData={data?.activities}
            handlePagination={handlePagination}
            total={data?.totalCount}
            limit={10}
            activePage={activePage}
            isEditable
            handleSort={handleSort}
          />
        </div>
        <ActivityDialogDesktop
          isOpen={isModalOpen}
          activityEditId={activityEditId}
          SetExitModal={setIsModalOpen}
          SetActivityEditId={SetActivityEditId}
          editMode={true}
         />
      </Drawer>
    </div>
  )
};

function mapStateToProps(state) {
  return {
    activity: state.activity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(ActivityActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ToDoListGrid);